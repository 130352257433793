.wrap{
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    position: fixed;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
}
.black{
    fill: var(--color-black);
}
.white{
    fill: white;
}
.white__icons{
    background-color: #ffffff;
    border-radius: 15px;
    padding: 15px 10px;
}
.black__icons{
    background-color: var(--color-black);
    border-radius: 15px;
    padding: 15px 10px;
}
.wrap > a > svg{
    width: 28px;
    height: 28px;
}

@media (max-width: 600px){
    .wrap{
        display: none;
    }
}