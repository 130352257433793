.form__wrap{
    width: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 1005;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000055;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form__wrap__scroll{
    width: 40%;
    max-width: 80%;
    max-height: 80vh;
    overflow-y: scroll;
    transition: all .3s;
}
.close{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.close > img{
    width: 15px;
    transition: all .3s;
    cursor: pointer;
}
.close > img:hover{
    transform: rotate(90deg);
}
.form{
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: calc(100% - 50px);
    padding: 20px 25px;
}
.inner{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.two{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.two__dop{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
input[type="text"], input[type="email"], textarea, .select{
    font-family: "Montserrat";
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 10px;
    border: solid 1px #000000;
}
textarea{
    resize: vertical;
}
.one__dop{
    font-family: "Montserrat";
    font-size: 14px;
    width: max-content;
    box-sizing: border-box;
    padding: 7px 15px;
    border-radius: 10px;
    background-color: #00000022;
}
textarea{
    font-size: 14.5px;
}
.two__dop > p{
    font-family: "Montserrat";
    font-size: 14px;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 10px;
    border: solid 1px #000000;
}
.label{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: auto;
}
.input__text{
    font-size: 16px;
}
.label__text{
    margin: 0;
    cursor: pointer;
}
.btn{
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px;
    color: #fff;
    background-color: #000000;
    border-radius: 20px;
    width: 70%;
    display: block;
    margin: auto;
    transition: all .3s;
    cursor: pointer;
    box-shadow: 0 0 10px 2px #ffd80066;
}
.btn:hover{
    transform: scale(1.02);
}
.errorText{
    color: red;
}
.input__text > a{
    border-bottom: solid 1px #000000;
}
@media (max-width: 600px){
    .form__wrap__scroll{
        width: 100%;
    }
    .btn{
        width: 85%;
        font-size: 12px;
    }
    .form{
        padding: 10px;
        width: calc(100% - 20px);
    }
    .inner{
        gap: 15px;
    }
    .input__text{
        font-size: 12px;
    }
    .two{
        flex-direction: column;
    }
}