.main__slider__wrap{
    position: relative;
    overflow: hidden;
    height: calc(100vh - 120px);
    border-radius: 20px;
    background: linear-gradient(#ffd700, #ffcc00);
    display: flex;
    align-items: center;
}
.main__slider__inner{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.4fr;  
    gap: 30px; 
}
.main__slider__text{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.main__slider__text__inner{
    width: calc(95% - 30px);
    display: flex;
    flex-direction: column;
    gap: 0px;
    position: relative;
}
.main__slider__black__line{
    background: url("../../img/front-page/black__line.png") no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: 105%;
    padding: 15% 0 18% 0;
}
.main__slider__black__line > h1{
    padding-left: 20px;
    font-size: 38px;
    word-spacing: 5px;
    letter-spacing: 2px;
}
.main__slider__text__inner > p{
    font-size: 19px;
    padding-left: 10px;
    font-weight: var(--bold-text);
}
.main__slider__title{
    font-family: 'Mistral';
    font-weight: var(--bold-text);
    text-transform: uppercase;
    color: var(--color-white);
}
.main__slider__yel{
    width: 120%;
    height: 140vh;
    border-radius: 50%;   
    background-color: #282828;
    box-shadow: 18px 30px 0 20px #ffeb7b;
    display: flex;
    align-items: center;
}
.main__slider__but{
    text-transform: uppercase;
    box-shadow: 0 0 20px 5px #ffdd49;
    width: 90%;
    background-color: var(--color-white);
    text-align: center;
    padding: 12px;
    font-size: 18px;
    margin-top: 30px;
    margin-left: 10px;
    border-radius: 20px;
    font-weight: var(--bold-text);
}
.main__slider__im{
    width: 78%;
    padding-left: 20px;
}

@media (max-width: 600px){
    .main__slider__wrap{
        height: auto;
    }
    .main__slider__inner{
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .main__slider__text__inner{
        width: 85%;
        gap: 10px;
    }
    .main__slider__text{
        justify-content: center;
        margin-top: 40px;
    }
    .main__slider__black__line{
        padding: 14% 0% 13% 0%;
        background-size: 110%;
    }
    .main__slider__yel{
        height: auto;
        width: 85%;
        margin: auto;
        justify-content: center;
        margin-bottom: 40px;
        box-shadow: 7px 7px 0 5px #ffeb7b;
    }
    .main__slider__im{
        padding: 60px 0;
        width: 75%;
    }
    .main__slider__but{
        width: auto;
        margin-top: 10px;
        font-size: 16px;
    }
    .main__slider__text__inner > p{
        font-size: 16px;
    }
    .main__slider__text__inner > h1{
        padding: 0;
    }
    .main__slider__text h1{
        font-size: 26px;
    }
}