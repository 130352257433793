.main__wrap{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    display: flex;
}
.wrap{
    width: 20%;
    background-color: #282828;
    border-bottom-left-radius: 40px;
}
.in{
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.close__wrap{
    width: 80%;
    opacity: 0;
}
.chrest{
    margin-top: 20px;
    fill: #ffffff;
    cursor: pointer;
    transition: all .3s;
}
.chrest:hover{
    transform: rotate(180deg);
}
.social{
    position: relative;
    top: 10%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.social > a > img{
    width: 55%;
    min-width: 25px;
    max-width: 30px;
    display: block;
    margin: auto;
}

@media (max-width: 600px){
    .main__wrap{
        height: 70vh;
    }
    .wrap{
        width: 70%;
    }
    .close__wrap{width: 30%;}
    .social > a > img{
        width: 60%;
        max-width: 30px;
    }
}