.header__wrap{
    width: 100%;
    background: url('./../../img/mainBack.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
.header{
    width: 75%;
    margin: auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}
.burger__wrap{
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.logo__wrap img{
    width: 100px;
}
.burger{
    display: flex;
    flex-direction: column;
    gap: 3px;
    height: 20px;
    width: 25px;
    position: relative;
}
.span{
    left: 0;
    width: 25px;
    background-color: var(--color-black);
    display: block;
    transition: all .3s;
    height: .4vh;
}
.b2, .b3{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.b4{
    position: absolute;
    bottom: 0;
}
.burger.active .b1,.burger.active .b4{
    opacity: 0;
}
.burger.active .b2{
    transform: translateY(-50%) rotate(45deg);
}
.burger.active .b3{
    transform: translateY(-50%) rotate(-45deg);
}
.links__wrap{
    display: flex;
    align-items: center;
    gap: 30px;
}
.nav{
    position: relative;
    top: 5%;
}
.ul{
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;
    text-align: right;
}
.ul li a{
    color: #ffffff;
    font-weight: var(--bold-text);
}
.lang__wrap{
    display: flex;
    align-items: center;
    gap: 10px;
}
.line{
    width: 1.5px;
    background-color: var(--color-black);
    height: 35px;
}
.lang__item{
    cursor: pointer;
}
.lang__item__push{
    font-weight: var(--bold-text);
}

@media (max-width: 500px){
    .logo__wrap img{
        width: 70px;
    }
    .links__wrap{
        gap: 15px;
    }
    .phone{
        display: none;
    }
}