.footer__wrap{
    width: 100%;
    margin-top: var(--margin-top);
    background-color: var(--color-black)
}
.footer__logo img{
    width: 15%;
    display: block;
    margin: auto;
    padding: 60px 0;
}
.footer__inner{
    width: 75%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 2.5fr 1fr;
    gap: 50px;
    color: var(--color-white);
}
.footer__card a{
    color: var(--color-white);   
}
.footer__card__in{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
}
.footer__card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.footer__map{
    width: 100%;
    height: 300px;
    border-radius: 20px;
    box-shadow: 0 0 15px 0px #ffd700;
}
.footer__card a{
    font-size: 16px;   
}
.footer__card p{
    font-size: 16px;
}
.footer__map__main{
    height: auto;
}
.footer__soc{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.footer__soc img{
    width: 70%;
}
.footer__card > h3, .footer__card__in__dop > h3{
    font-size: 16px;
    font-weight: var(--bold-text);
}
.footer__card__in__dop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.copyright__wrap{
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    gap: 5px;
    color: var(--color-white);
    padding: 30px 0;
}

@media (max-width: 600px){
    .footer__inner{
        grid-template-columns: 1fr;
        width: 75%;
        gap: 25px;
    }
    .footer__card__in{
        gap: 15px;
        text-align: center;
    }
    .footer__logo img{
        width: 30%;
    }
    .footer__card a{
        font-size: 14px;   
    }
    .footer__card p{
        font-size: 14px;
    }
    .footer__soc img{
        width: 60%;
    }
    .footer__card{
        gap: 15px;
        text-align: center;
    }
    .copyright__wrap{
        font-size: 12px;
        padding: 20px 0;
    }
}