.body{
    background: url("./../../img/mainBack.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.width85{
    width: 85%;
    margin: auto;
}
.width90{
    width: 90%;
    margin: auto;
}
.width100{
    width: 100%;
    overflow: hidden;
}
.width75{
    width: 75%;
    margin: auto;
}
.mane__title{
    font-family: 'Mistral';
    font-size: 36px;
    text-align: center;
    word-spacing: 5px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: var(--margin-top);
    font-weight: var(--bold-text);
}
.review__slider__dop__title{
    text-align: center;
    margin-top: 10px;
} 
.services__wrap{
    width: 100%;
    margin: auto;
    margin-top: var(--margin-top);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}
.sesrvices{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.services__inner{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
}
.services__inner p{
    font-weight: var(--bold-text);
    margin: 0;
    border-radius: 5px;
    border: solid 1px #fff;
    padding: 5px;
    transition: all .3s;
    cursor: pointer;
}
.services__inner p:hover{
    border: solid 1px #000000;
    padding: 5px;
}
.services__title{
    font-weight: var(--bold-text);
    text-transform: uppercase;
    padding-bottom: 5px;
}
.services__title__one{
    border-bottom: solid 1.5px #000000;
}   
.services__title__three{
    border-bottom: dashed 1.5px #000000;
}
.services__title__two{
    position: relative;
}
.services__title__two::after{
    content: url('./../../img/front-page/line.png');
    height: auto;
    position: absolute;
    top: 10px;
    left: 0;
}
.why__we__wrap{
    width: 100%;
    background-color: var(--color-black);
    color: #fff;
    padding: 30px 0;
}
.why__we__bot__image{
    width: 100%;
}
.why__we__top{
    display: flex;
}
.why__we__top__image{
    width: 100%;
    margin-top: var(--margin-top);
}
.why__we__in{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 60px;
}
.why__we__title{
    font-family: 'Mistral';
    text-transform: uppercase;
    word-spacing: 5px;
    letter-spacing: 2px;
    font-size: 36px;
    font-weight: var(--bold-text);
}
.why__we__inner{
    width: 75%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}
.why__we__back{
    width: 100%;
    height: max-content;
    border-radius: 50%;
    background: rgba(255,216,0,0.2);
    background: -moz-radial-gradient(center, ellipse cover, rgba(255,216,0,0.2) 0%, rgba(254,190,54,0.01) 56%, rgba(254,182,69,0.01) 72%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255,216,0,0.2)), color-stop(56%, rgba(254,190,54,0.01)), color-stop(72%, rgba(254,182,69,0.01)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(255,216,0,0.2) 0%, rgba(254,190,54,0.01) 56%, rgba(254,182,69,0.01) 72%);
    background: -o-radial-gradient(center, ellipse cover, rgba(255,216,0,0.2) 0%, rgba(254,190,54,0.01) 56%, rgba(254,182,69,0.01) 72%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(255,216,0,0.2) 0%, rgba(254,190,54,0.01) 56%, rgba(254,182,69,0.01) 72%);
    background: radial-gradient(ellipse at center, rgba(255,216,0,0.2) 0%, rgba(254,190,54,0.01) 56%, rgba(254,182,69,0.01) 72%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd800', endColorstr='#feb645', GradientType=1 );
}
.why__we{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    
}
.why__we__image{
    display: flex;
    padding: 15px 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.why__we > p{
    max-width: 80%;
    margin: auto;
}
.why__we__im{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: auto;
    /*box-shadow: 0 45px 130px #a08916;*/
} 
.title{
    text-transform: uppercase;
    font-weight: var(--bold-text);
}
.about__wrap{
    width: 100%;
    margin-top: var(--margin-top);
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    gap: 50px;
}
.about__title{
    text-transform: uppercase;
    font-weight: var(--bold-text);
    font-size: 28px;
    margin: 10px 0 20px 0;
}
.about__title > span{
    font-family: 'Mistral';
}
.about__text{
    margin-bottom: 10px;
}
.about__img{
    background: url("../../img/front-page/logo-512x512.png");
    background-position-x: center;
    background-position-y: center;
    background-size: 80%;
    background-repeat: no-repeat;
    border-radius: 20px;
    min-height: 40vh;
}
.techno{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
    column-gap: 25px;
    row-gap: 35px;
    margin-top: 30px;
}
.techno__card{
    width: 85%;
    margin: auto;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 #00000033;
    background-color: #ffffff;
    overflow: hidden;
}
.card__black{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black);
}
.card__img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0 25px 0;
}
.techno__card img{
    width: 70px;
}
.card__text{
    color: #fff;
    text-align: center;
}
.black__card{
    height: 200px;
    width: 85%;
    margin: auto;
    background-color: var(--color-black);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/**/
.form__wrap{
    width: 100%;
    background: url('../../img/front-page/formMid2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.form__in{
    width: 75%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 50px;
    padding: 30px 0;
}
.form__top{
    display: flex;
    margin-top: var(--margin-top);
}
.form__top__img{
    width: 100%;
}
.form__bot__img{
    width: 100%;
}
.form__text{
    display: flex;
    align-items: center;
}
.form__text__in{
    display: flex;
    flex-direction: column;
    gap: 70px;
}
.form__title{
    font-family: 'Mistral';
    text-transform: uppercase;
    font-size: 38px;
    word-spacing: 5px;
    letter-spacing: 2px;
    font-weight: var(--bold-text);
}
.form__text__text{
    font-size: 18px;
    font-weight: var(--bold-text);
}
/**/
.achievement__top{
    margin-top: var(--margin-top);
}
.achievement__top img, .achievement__bot img{
    width: 100%;
    display: flex;
}
.achievement__wrap{
    background: url("../../img/front-page/bigyelbackcenter.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.achievement__in{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}
.achievement__card{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
}
.achievement__main__title{
    font-family: 'Mistral';
    font-size: 60px;
    font-weight: var(--bold-text);
}
.achievement__title{
    text-transform: uppercase;
    font-weight: var(--bold-text);
}
.slider__reviews{
    display: none;
}

@media (max-width: 600px){
    .services__wrap{
        grid-template-columns: 1fr;
        margin-top: 40px;
        gap: 20px;
    }
    .services__title{
        font-size: 16px;
    }
    .sesrvices{
        gap: 10px;
    }
    .services__inner{
        gap: 15px;
    }
    .sesrvices > p{
        font-size: 14px;
    }
    .why__we__top__image{
        margin-top: 40px;
    }
    .why__we__inner{
        width: 75%;
        grid-template-columns: 1fr;
        gap: 25px;
    }
    .why__we__in{
        gap: 30px;
    }
    .why__we{
        gap: 15px;
    }
    .why__we p{
        font-size: 14px;
    }
    .why__we__title{
        display: block;
        font-size: 24px;
        width: 80%;
        text-align: center;
    }
    .title{
        font-size: 16px;
    }
    .form__top{
        margin-top: 40px;
    }
    .form__in{
        grid-template-columns: 1fr;
        gap: 30px;
    }
    .form__text__in{
        gap: 20px;
    }
    .form__title{
        font-size: 24px;
    }
    .form__text__text{
        font-size: 16px;
    }
    .about__wrap{
        grid-template-columns: 1fr;
        gap: 0px;
        width: 95%;
        margin: auto;
        margin-top: 40px;
    }
    .about__img{
        height: 30vh;
        max-height: 35vh;
        min-height: auto;
    }
    .about__text{
        margin: 0;
        font-size: 14px;
    }
    .techno{
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 0px;
        row-gap: 20px;
        margin-top: 25px;
    }
    .card__black{
        height: 55px;
    }
    .black__card{
        height: 135px;
    }
    .achievement__top{
        margin-top: 40px;
    }
    .achievement__main__title{
        font-size: 45px;
    }
    .achievement__card{
        gap: 10px;
    }
    .achievement__in{
        grid-template-columns: 1fr;
    }
    .achievement__title{
        font-size: 16px;
    }
    .achievement__in{
        gap: 30px;
    }
    .width85{
        width: 90%;
        margin: auto;
    }
    .width90{
        width: 100%;
        margin: auto;
    }
    .width100{
        width: 100%;
        overflow: hidden;
    }
    .width75{
        width: 80%;
        margin: auto;
    }
    .mane__title{
        font-size: 24px;
        margin-top: 40px;
    }
    .about__title{
        font-size: 22px;
        margin: 0 0 10px 0;
    }
    .card__text{
        font-size: 12px;
    }
    .card__img{
        padding: 15px 0;
    }
    .card__img > img{
        width: 50px;
        height: 50px;
    }
}