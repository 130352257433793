.inner{
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
}
.slider{
    display: grid;
    width: 100%;
    transition: all .3s;
}
.wrap{
    position: relative;
}
.arrow{
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #ffd700;
}
.arrow__left{
    left: 0%;
    transform: translate(-120%, -50%);
    background: url(./../../img/projects/triangle__left.png) no-repeat;
    background-size: 75%;
    background-position: center;
}
.arrow__right{
    right: 0%;
    transform: translate(+120%, -50%);
    background: url(./../../img/projects/triangle__right.png) no-repeat;
    background-size: 75%;
    background-position: center;
}
.img{
    width: 100%;
}
.item{
    transition: all .3s;
    position: relative;
}
.item__link{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../img/projects/icons8-показать-60.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #ffffff66;
    opacity: 0;
    top: 0;
    left: 0;
    transition: all .3s;
}
.item__link:hover{
    opacity: 1;
}

@media (max-width: 600px){
    .arrow{
        width: 30px;
        height: 30px;
    }
    .arrow__left{
        left: 2%;
    }
    .arrow__right{
        right: 2%;
    }
    .inner{
        margin-top: 15px;
    }
}