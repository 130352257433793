.wrap{
    width: 100%;
    margin: auto;
    border-radius: 25px;
    box-shadow: 0 0 10px 0 #00000033;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.form{
    width: 75%;
    padding: 25px;
}
.inner{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.two{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
input[type="text"], input[type="email"], textarea{
    font-family: "Montserrat";
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 10px;
    border: solid 1px #000000;
}
textarea{
    font-size: 14.5px;
}
.label{
    display: flex;
    flex-direction: row;
    margin: auto;
    gap: 5px
}
.label__text{
    margin: 0;
    cursor: pointer;
}
.input__text{
    font-size: 16px;
}
.input__text > a{
    border-bottom: solid 1px #000000;
}
.btn{
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px;
    color: #fff;
    background-color: #000000;
    border-radius: 15px;
    width: 70%;
    display: block;
    margin: auto;
    transition: all .3s;
    cursor: pointer;
    box-shadow: 0 0 10px 2px #ffd80066;
}
.btn:hover{
    transform: scale(1.02);
}
.errorText{
    color: red;
}

@media (max-width: 600px){
    .form{
        width: 100%;
    }
    .btn{
        width: 100%;
        font-size: 14px;
    }
    .two{
        display: flex;
        flex-direction: column;
    }
    .inner{
        gap: 15px;
    }
    .input__text{
        font-size: 12px;
    }
    .btn{
        font-size: 12px;
    }
}