.inner{
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
}
.slide__in{
    padding: 20px;
}
.img{
    width: 50%;
}
.slider{
    display: grid;
    width: 100%;
    transition: all .3s;
}
.wrap{
    position: relative;
}
.arrow{
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.arrow__left{
    left: 0%;
    transform: translate(-120%, -50%);
    background: url(./../../img/reviews/triangle__left.png) no-repeat;
    background-size: 75%;
    background-position: center;
}
.arrow__right{
    right: 0%;
    transform: translate(+120%, -50%);
    background: url(./../../img/reviews/triangle__right.png) no-repeat;
    background-size: 75%;
    background-position: center;
}
.slide{
    padding: 10px;
}
.slide__in{
    border-radius: 23px;
}
.shadow{
    box-shadow: 0 0 10px 0 #22222233;
    background-color: #ffffff;
}
.color{
    background: linear-gradient(to right bottom, #ffd800, #ffc602);
}
.item{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}
.title{
    font-weight: var(--bold-text);
}
.dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 2px #222222;
}
.dots{
    width: max-content;
    margin: auto;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.dot__active{
    background-color: #222222;
}

@media (max-width: 600px){
    .arrow{
        width: 30px;
        height: 30px;
    }
    .arrow__left{
        left: 2%;
    }
    .arrow__right{
        right: 2%;
    }
    .title{
        font-size: 16px;
    }
    .item__text{
        font-size: 14px;
    }
    .inner{
        margin-top: 15px;
    }
}