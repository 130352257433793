@font-face {
	font-family: 'Montserrat';
	src: url('../public/fonts/Montserrat/Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('../public/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
		url('../public/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
  font-display: Arial, sans-serif;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../public/fonts/Montserrat/Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('../public/fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
		url('../public/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  font-display: Roboto, sans-serif;
}
@font-face {
  font-family: 'Mistral';
  src: url('../public/fonts/Mistral/Mistral.eot');
  src: local('Mistral'),
      url('../public/fonts/Mistral/Mistral.eot') format('embedded-opentype'),
      url('../public/fonts/Mistral/Mistral.woff2') format('woff2'),
      url('../public/fonts/Mistral/Mistral.woff') format('woff'),
      url('../public/fonts/Mistral/Mistral.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: Arial, sans-serif;
}

:root{
  --color-black: #181818;
  --bold-text: 700;
  --color-white: #ffffff;
  --margin-top: 80px
}
* {
  scrollbar-width: thin;
  scrollbar-color: #000000 #ffd800;
  scroll-behavior: smooth;
}
::selection{
  background-color: #000000;
  color: #ffd800;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #ffd800;
}
*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 20px;
  border: 0px none #000000;
}
*{
  padding: 0;
  margin: 0;
  font-family: "Montserrat";
  font-weight: normal;
	font-style: normal;
}
a{
  color: #000000;
  text-decoration: none;
  transition: all .3s;
}
a:hover{
  transform: scale(1.05);
}